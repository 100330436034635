import Excel from "exceljs";
import { Dictionary, keys, map } from "lodash";
import axios from "axios";
import {
  TodoAny,
  TodoArray,
  TodoMap,
} from "@auditcloud/shared/lib/utils/type-guards";

import { createError } from "./Errors";
import {
  tt2str,
  typeIsTranslateableText,
} from "@auditcloud/shared/lib/types/common";
import { parseDownloadUrl } from "./storage";
import { ct } from "@/plugins/ContentTranslation";
import {
  ExcelMetadataCollection,
  FlatReportData,
} from "@/store/modules/audit/getters";
import { ExcelExportConfig } from "@auditcloud/shared/lib/schemas";

export interface TodoExportDataConfig {
  currentExcelExport: TodoAny;
  currentAuditReportSummary: TodoAny;

  currentCalcScore: TodoAny;
}

const raw_data_cols = [
  { header: "Audit Item Id", key: "auditItemId", width: 32 },
  {
    header: "Audit Item Category",
    key: "auditItemCategory",
    width: 32,
  },
  { header: "Audit Item Weight", key: "auditItemWeight", width: 32 },
  { header: "Audit Item Text", key: "auditItemText", width: 32 },
  {
    header: "Finding Weight Best Possible",
    key: "findingWeightBestPossible",
    width: 32,
  },
  {
    header: "Finding Weight Worst Possible",
    key: "findingWeightWorstPossible",
    width: 32,
  },

  { header: "Finding Id", key: "findingId", width: 32 },
  { header: "Finding Note", key: "findingNote", width: 32 },
  { header: "Finding Weight", key: "findingWeight", width: 32 },
  { header: "Finding Type Id", key: "findingTypeId", width: 32 },
  { header: "Finding Type Name", key: "findingTypeName", width: 32 },
  { header: "Finding Type Abbr", key: "findingTypeAbbr", width: 32 },
  { header: "Finding Type Desc", key: "findingTypeDesc", width: 32 },

  { header: "Measure Id", key: "measureId", width: 32 },
  { header: "Measure Type Id", key: "measureTypeId", width: 32 },
  { header: "Measure Type Name", key: "measureTypeName", width: 32 },
  { header: "Measure Type Abbr", key: "measureTypeAbbr", width: 32 },
  { header: "Measure Type Desc", key: "measureTypeDesc", width: 32 },
  { header: "Measure Note", key: "measureNote", width: 32 },
  { header: "Measure Desc", key: "measureDesc", width: 32 },
  {
    header: "Measure cause_analysis",
    key: "measureCauseAnalysis",
    width: 32,
  },
  { header: "Measure due date", key: "measureDueDate", width: 32 },
  {
    header: "Measure assigned to",
    key: "measureAssignedTo",
    width: 32,
  },
  {
    header: "Measure create date",
    key: "measureCreateDate",
    width: 32,
  },
  { header: "Measure Author", key: "measureAuthor", width: 32 },
  { header: "Measure Status", key: "measureStatus", width: 32 },
  { header: "Measure Priority", key: "measurePriority", width: 32 },
  { header: "Audit Item Number", key: "auditItemNo", width: 32 },
  { header: "Audit Item Text DE", key: "auditItemTextDe", width: 32 },
  { header: "Audit Item Text EN", key: "auditItemTextEn", width: 32 },
];

const col2XlsCol = (colIdx: string | number) => {
  const colIdxN = typeof colIdx === "number" ? colIdx : parseInt(colIdx, 10);
  console.assert(colIdx > 0, "colIdx must be 1-based");
  colIdx = colIdxN - 1;
  const radix = 26;
  const a_codepoint = "A".codePointAt(0) || 65;
  let result = "";
  do {
    const offset = colIdxN % radix;
    result = String.fromCodePoint(a_codepoint + offset) + result;
    colIdx = Math.floor((colIdx - offset) / radix) - 1;
  } while (colIdx >= 0);

  return result;
};

const loadTemplate = (url: string) => {
  console.log("loadTemplate", url);
  return axios({
    method: "GET",
    url,
    responseType: "arraybuffer",
  }).then(res => {
    if (res.status === 200) {
      // const uintArray = new Uint8Array(res.data);

      const workbook = new Excel.Workbook();
      return workbook.xlsx.load(res.data);
      // return workbook;
      // resolve(response.data);
    } else {
      throw createError("Download Excel Template failed", url, res);
    }
  });
};

const handleDataAuditSheet = async (
  AuditReportingVueThis: TodoExportDataConfig,
  workbook: Excel.Workbook
) => {
  console.log("handleDataAuditSheet", AuditReportingVueThis, workbook);
  const audit_sheet = workbook.addWorksheet("DataAudit");
  audit_sheet.getColumn(5).numFmt = "0.00%";

  const audit_data = AuditReportingVueThis.currentExcelExport;

  audit_sheet.addRows(audit_data);
  audit_sheet.state = "veryHidden";
  return workbook;
};
const handleDataSheet = async (
  flatFindingsList: TodoArray,
  workbook: Excel.Workbook
) => {
  console.log("handleDataSheet", flatFindingsList, workbook);
  // Create Reporting by raw data:
  const raw_data_sheet = workbook.addWorksheet("Data");

  raw_data_sheet.columns = raw_data_cols;
  raw_data_sheet.addRows(flatFindingsList);
  raw_data_sheet.state = "veryHidden";
  return workbook;
};

const handleExportSheet = async (
  flatFindingsList: TodoArray,
  workbook: Excel.Workbook,
  export_sheet_name: string,
  export_sheet_row_offset: number,
  export_sheet_cols: (string | null)[]
) => {
  console.log(
    "handleExportSheet",
    flatFindingsList,
    workbook,
    export_sheet_name
  );

  const raw_data = flatFindingsList;
  const actionlist_sheet = workbook.getWorksheet(export_sheet_name);

  console.log("Length of Data", raw_data.length);

  const getSourceExcelCol = (ColKey: string) => {
    const col = raw_data_cols.findIndex(v => v.key === ColKey);
    console.assert(col >= 0, "Known ColKey expected.", ColKey);
    return col2XlsCol(col + 1);
  };
  const source_header_offset = 1;
  const target_row_offset = export_sheet_row_offset;
  const target_col_offset = 0;

  const raw_export_rows: Dictionary<(rowIdx: number) => TodoAny> = {
    no: rowIdx => {
      const auditItemNoCol = getSourceExcelCol("auditItemNo");
      if (auditItemNoCol) {
        return {
          result: null,
          formula: `IF(ISBLANK(Data!${auditItemNoCol}${rowIdx}),"",Data!${auditItemNoCol}${rowIdx})`,
        };
      } else {
        return rowIdx - source_header_offset;
      }
    },

    auditItemText: rowIdx => {
      const auditItemTextCol = getSourceExcelCol("auditItemText");
      return {
        result: null,

        formula: `IF(ISBLANK(Data!${auditItemTextCol}${rowIdx}),"",Data!${auditItemTextCol}${rowIdx})`,
        alignment: { wrapText: true },
      };
    },

    auditItemTextDe: rowIdx => {
      const auditItemTextCol = getSourceExcelCol("auditItemTextDe");
      return {
        result: null,

        formula: `IF(ISBLANK(Data!${auditItemTextCol}${rowIdx}),"",Data!${auditItemTextCol}${rowIdx})`,
        alignment: { wrapText: true },
      };
    },

    auditItemTextEn: rowIdx => {
      const auditItemTextCol = getSourceExcelCol("auditItemTextEn");
      return {
        result: null,

        formula: `IF(ISBLANK(Data!${auditItemTextCol}${rowIdx}),"",Data!${auditItemTextCol}${rowIdx})`,
        alignment: { wrapText: true },
      };
    },

    findingNote: rowIdx => {
      const findingNoteCol = getSourceExcelCol("findingNote");
      return {
        result: null,

        formula: `IF(ISBLANK(Data!${findingNoteCol}${rowIdx}),"",Data!${findingNoteCol}${rowIdx})`,
        alignment: { wrapText: true },
      };
    },
    findingWeightBestPossible: rowIdx => {
      const findingWeightBestPossibleCol = getSourceExcelCol(
        "findingWeightBestPossible"
      );
      return {
        result: null,

        formula: `IF(ISBLANK(Data!${findingWeightBestPossibleCol}${rowIdx}),"",Data!${findingWeightBestPossibleCol}${rowIdx})`,
      };
    },
    findingWeight: rowIdx => {
      const findingWeightCol = getSourceExcelCol("findingWeight");
      return {
        result: null,

        formula: `IF(ISBLANK(Data!${findingWeightCol}${rowIdx}),"",Data!${findingWeightCol}${rowIdx})`,
      };
    },

    findingTypeAbbr: rowIdx => {
      const findingTypeAbbrCol = getSourceExcelCol("findingTypeAbbr");
      return {
        result: null,

        formula: `IF(ISBLANK(Data!${findingTypeAbbrCol}${rowIdx}),"",Data!${findingTypeAbbrCol}${rowIdx})`,
      };
    },
    measureCauseAnalysis: rowIdx => {
      const measureCauseAnalysisCol = getSourceExcelCol("measureCauseAnalysis");
      return {
        result: null,

        formula: `IF(ISBLANK(Data!${measureCauseAnalysisCol}${rowIdx}),"",Data!${measureCauseAnalysisCol}${rowIdx})`,
        alignment: { wrapText: true },
      };
    },
    measureFullNote: rowIdx => {
      const measureNotesCol = getSourceExcelCol("measureNote");
      const measureDescCol = getSourceExcelCol("measureDesc");
      return {
        result: null,
        formula: `IF(ISBLANK(Data!${measureNotesCol}${rowIdx}),"",Data!${measureNotesCol}${rowIdx})&IF(ISBLANK(Data!${measureDescCol}${rowIdx}),"",IF(ISBLANK(Data!${measureNotesCol}${rowIdx}),"",CHAR(10))&Data!${measureDescCol}${rowIdx})`,
        alignment: { wrapText: true },
      };
    },
    measureTypeAbbr: rowIdx => {
      const measureTypeAbbrCol = getSourceExcelCol("measureTypeAbbr");
      return {
        result: null,

        formula: `IF(ISBLANK(Data!${measureTypeAbbrCol}${rowIdx}),"",Data!${measureTypeAbbrCol}${rowIdx})`,
      };
    },
    measureAssignedTo: rowIdx => {
      const measureAssignedToCol = getSourceExcelCol("measureAssignedTo");
      return {
        result: null,

        formula: `IF(ISBLANK(Data!${measureAssignedToCol}${rowIdx}),"",Data!${measureAssignedToCol}${rowIdx})`,
        alignment: { wrapText: true },
      };
    },
    measureDueDate: rowIdx => {
      const measureDueDateCol = getSourceExcelCol("measureDueDate");
      return {
        result: null,

        formula: `IF(ISBLANK(Data!${measureDueDateCol}${rowIdx}),"",Data!${measureDueDateCol}${rowIdx})`,
      };
    },
  };

  const raw_columns_mapping = export_sheet_cols.map(name => {
    if (typeof name === "string" && name in raw_export_rows) {
      return raw_export_rows[name];
    } else {
      console.warn("UNKNOWN COLUMN", name);
      return null;
    }
  });

  for (let row_idx = 1; row_idx <= raw_data.length; row_idx++) {
    const ro: TodoArray = []; //getActionlistRow(i + 2);
    for (const raw_column_mapping of raw_columns_mapping) {
      if (typeof raw_column_mapping === "function") {
        ro.push(raw_column_mapping(row_idx + source_header_offset));
      } else {
        ro.push(raw_column_mapping);
      }
    }

    actionlist_sheet.addRow(ro);
  }
  const autoFilter =
    col2XlsCol(target_col_offset + 1) +
    target_row_offset +
    ":" +
    col2XlsCol(target_col_offset + raw_columns_mapping.length) +
    (target_row_offset + raw_data.length);
  console.log("AutoFilter", autoFilter);
  actionlist_sheet.autoFilter = autoFilter;
  return workbook;
};

const handleTemplateInputSheet = (
  excelMetadataCollection: ExcelMetadataCollection,
  workbook: Excel.Workbook
) => {
  console.log(
    "Data for the TemplateInput-Sheet: ",
    keys(excelMetadataCollection),
    excelMetadataCollection
  );

  const templateInputSheet = workbook.getWorksheet("TemplateInput");
  if (templateInputSheet) {
    const templateInputValueColumn = templateInputSheet.getColumn("B");
    if (templateInputValueColumn.eachCell) {
      templateInputValueColumn.eachCell(cell => {
        if (cell.name in excelMetadataCollection) {
          cell.value = excelMetadataCollection[cell.name];
        }
      });
    }
    templateInputSheet.state = "hidden"; // "veryHidden";
  } else {
    console.warn(`try to init "TemplateInput" - Sheet not found`);
  }

  return workbook;
};

const handleReportSheet = async (
  workbook: Excel.Workbook,
  report_sheet_name: string
) => {
  console.log("handleReportSheet", workbook, report_sheet_name);
  // cleanup results so that Excel recalculates the result on load of the workbook
  const report_sheet = workbook.getWorksheet(report_sheet_name);
  if (report_sheet) {
    report_sheet.eachRow(function (row) {
      row.eachCell(function (cell) {
        if (typeof cell.formula !== "undefined") {
          cell.value = { formula: cell.formula, date1904: false };
        }
      });
    });
  }
  return workbook;
};
interface ValueCellMapping {
  [fieldName: string]: {
    rowIdx: number;
    colIdx: number;
  };
}

function findColsInSheet(sheet: Excel.Worksheet, knownColumnNames: string[]) {
  console.log("findColsInSheet", sheet);
  const mapping: ValueCellMapping = {};

  if (sheet) {
    sheet.eachRow((row, rowIdx) => {
      row.eachCell((cell, colIdx) => {
        if (knownColumnNames.includes(cell.name)) {
          mapping[cell.name] = {
            rowIdx,
            colIdx,
          };
        }
      });
    });
  } else {
    console.warn("expect Sheet");
  }

  return mapping;
}

function fillExportData(
  sheet: Excel.Worksheet,
  rowData: FlatReportData,
  mapping: ValueCellMapping
) {
  const dataNames = Object.keys(mapping);

  if (dataNames.length > 0) {
    rowData.forEach((val, dataIdx) => {
      dataNames.forEach(dataName => {
        const { rowIdx, colIdx } = mapping[dataName];
        const cell = sheet.getCell(rowIdx + dataIdx, colIdx);
        cell.value = val[dataName];
      });
    });
  }
}

function loadLocalXlsxFile() {
  console.log("loadLocalXlsxFile");
  return new Promise<Excel.Workbook>((resolve, reject) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "*.xlsx");

    input.style.visibility = "hidden";
    document.body.appendChild(input);
    input.addEventListener(
      "change",
      (changeEvent: Event) => {
        document.body.removeChild(input);

        if (
          (changeEvent.target as any).files instanceof FileList &&
          (changeEvent.target as any).files.length === 1
        ) {
          const files: FileList = (changeEvent.target as any).files;
          const file = files[0] as unknown as {
            arrayBuffer(): Promise<ArrayBuffer>;
          };

          file
            .arrayBuffer()
            .then((buffer: ArrayBuffer) => {
              const workbook = new Excel.Workbook();

              resolve(workbook.xlsx.load(buffer));
            })
            .catch(reject);
        } else {
          reject(createError("Invalid File selection"));
        }
      },
      false
    );
    input.click();
  });
}

const generateXlsxReport = (
  excelMetadataCollection: ExcelMetadataCollection,
  flatFindingsList: FlatReportData,
  excelExportConfig: ExcelExportConfig,
  useLocalTemplate: boolean = false,
  locale: string
) => {
  return async () => {
    console.log("GENERATE_XLSX_REPORT", useLocalTemplate);

    const loadExternalTemplate = async () => {
      const template_storagePath = tt2str(
        excelExportConfig.templateName,
        locale
      );
      const template_storageUrl = await parseDownloadUrl(template_storagePath);
      return await loadTemplate(template_storageUrl);
    };

    const workbook = useLocalTemplate
      ? await loadLocalXlsxFile()
      : await loadExternalTemplate();
    if (
      typeIsTranslateableText(excelExportConfig.contentListSheetName) &&
      flatFindingsList.length > 0
    ) {
      handleTemplateInputSheet(excelMetadataCollection, workbook);

      const columnNames = Object.keys(flatFindingsList[0]);

      const sheetName = ct(excelExportConfig.contentListSheetName);
      const exportSheet = workbook.getWorksheet(sheetName);
      console.assert(exportSheet, `expect to find sheet "${sheetName}"`);
      if (exportSheet) {
        const dataMapping = findColsInSheet(exportSheet, columnNames);
        console.log("ColNames", columnNames, dataMapping);
        fillExportData(exportSheet, flatFindingsList, dataMapping);
      }
    }

    workbook.calcProperties.fullCalcOnLoad = true;
    const data = await workbook.xlsx.writeBuffer();
    return new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  };
};

export default generateXlsxReport;
