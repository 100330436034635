


















/* tslint:disable:no-console */
import Vue from "vue";
import Component from "vue-class-component";
import { Getter } from "vuex-class";

import { AuditMetadataClient } from "@/types/Audit";
import { AuditStandardRef } from "@auditcloud/shared/lib/schemas";
import { api } from "@/store/modules/audit";
import AAuditReportMetadata from "@/components/widgets/AAuditReportMetadata.vue";
import AAuditResultDataWrapper from "@/components/widgets/AAuditResultDataWrapper.vue";
import AAuditResultSummary from "@/components/widgets/AAuditResultSummary.vue";
import ACardWithHeader from "@/components/widgets/ACardWithHeader.vue";
import { formatDates } from "@auditcloud/shared/lib/utils/formatting/dates";
import { nullable } from "@auditcloud/shared/lib/types/common";

@Component({
  components: {
    AAuditReportMetadata,
    AAuditResultDataWrapper,
    AAuditResultSummary,
    ACardWithHeader,
  },
})
export default class AAuditResultWidget extends Vue {
  tab: string = "";

  @Getter(api.getters.getAuditId, {
    namespace: api.namespace,
  })
  currentAuditId!: nullable<string>;

  get currentAuditMetadata() {
    const metadata = this.auditMetadata;
    if (metadata === null) {
      return [];
    }

    console.log("currentAuditMetadata", metadata);
    const result = [
      {
        field: "standard",
        description: this.$t("views.audit_reporting.description_standard"),
        value:
          metadata.standardRefs?.map(
            (standardRef: AuditStandardRef) => standardRef.name
          ) ?? [],
      },
      {
        field: "type",
        description: this.$t("views.audit_reporting.description_type"),
        value: metadata.type,
      },
      {
        field: "audited_by",
        description: this.$t("views.audit_reporting.description_audited_by"),
        value: metadata.audited_by,
      },
      {
        field: "scope",
        description: this.$t("views.audit_reporting.description_scope"),
        value:
          this.$hasFeature("featureUseProcessAsScope") &&
          metadata.type === "Prozessaudit"
            ? metadata.process
            : metadata.scope,
      },
      {
        field: "leadauditor",
        description: this.$t("views.audit_reporting.description_lead_auditor"),
        value: metadata.leadauditor ? metadata.leadauditor.displayName : " - ",
      },
      {
        field: "coauditors",
        description: this.$t("views.audit_reporting.description_co_auditor"),
        value:
          metadata.coauditors
            ?.map((coauditor: any) => coauditor.displayName)
            .join(", ") ?? "",
      },
      {
        field: "auditing_date",
        description: this.$t("views.audit_reporting.description_date"),
        value: formatDates(metadata.auditing_date ?? [], null).join(", "),
      },
    ];
    if (metadata.due_date_review) {
      result.push({
        field: "due_date_review",
        description: this.$t("views.audit_reporting.description_date_review"),
        value: formatDates(metadata.due_date_review ?? "", null).join(", "),
      });
    }
    if (metadata.due_date_final) {
      result.push({
        field: "due_date_final",
        description: this.$t("views.audit_reporting.description_date_final"),
        value: formatDates(metadata.due_date_final ?? "", null).join(", "),
      });
    }

    return result;
  }

  @Getter(api.getters.getAuditMetadata, {
    namespace: api.namespace,
  })
  auditMetadata!: AuditMetadataClient | null;
}
