



















import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { State, Action, Getter, Mutation } from "vuex-class";
import {
  TodoMap,
  TodoArray,
  TodoAny,
  TodoActionSignature,
  TodoMutationSignature,
} from "@auditcloud/shared/lib/utils/type-guards";

import { mapGetters } from "vuex";
import { api } from "@/store/modules/users";
import { UserInfoResolver } from "@/types/User";
// import user_resolver from "@/utils/user_resolver";

@Component({})
export default class SnippetUser extends Vue {
  @Prop({
    type: [Array, String],
    default: () => [],
  })
  currentUsers!: TodoAny;

  config = {
    displayName: true,
    email: false,
    avatar: true,
    format: "list", // chip,list,more to come
  };
  mapUsers(userlist) {
    // TODO: Inperformant -> Join sollte Serverseitig laufen. Check ob es im Anwendungsfall ausreicht
    const users: TodoAny = [];

    if (typeof this.currentUsers === "object") {
      for (const user in userlist) {
        const user_id = userlist[user];
        const user_object = this.getUser(user_id) || {
          id: user_id,
          displayName: user_id,
        };
        users.push(user_object);
      }
    } else {
      const user = userlist;
      const user_object = this.getUser(user) || {
        id: user,
        displayName: user,
      };
      users.push(user_object);
    }
    return users;
  }
  @Getter(api.getters.getUser, { namespace: api.namespace })
  getUser!: UserInfoResolver;

  get users() {
    return this.mapUsers(this.currentUsers);
  }
}
