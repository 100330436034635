




































































































import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { State, Action, Getter, Mutation } from "vuex-class";
import {
  TodoMap,
  TodoArray,
  TodoAny,
  TodoActionSignature,
  TodoMutationSignature,
} from "@auditcloud/shared/lib/utils/type-guards";

import SnippetScope from "@/components/snippets/SnippetScope.vue";
import SnippetUser from "@/components/snippets/SnippetUser.vue";

@Component({
  components: {
    SnippetScope,
    SnippetUser,
  },
})
export default class AAuditReportMetadata extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  })
  auditanalysis!: TodoArray;

  get audit_analysis() {
    return this.auditanalysis;
  }
}
